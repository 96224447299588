// Dependencies
import React, { useState, useEffect, useCallback, useRef } from "react"
import { graphql, navigate } from "gatsby"
import PropTypes from "prop-types"
import { useLocation } from "@reach/router"
import { useWheel, useDrag } from "react-use-gesture"
// Hooks & Helpers
import useLockBodyScroll from "../hooks/useLockBodyScroll"
import useSessionStorage from "../hooks/useSessionStorage"
import useTimeout from "../hooks/useTimeout"
// Wrappers
import Layout from "../wrappers/Layout"
import { useNavigationContext } from "../wrappers/Navigation"
// Components
import Image from "../components/Image"
import Moodboard from "../components/Moodboard"
import CampaignRows from "../components/CampaignRows"
import Lockup from "../components/Lockup"
import SplashVideo from "../components/SplashVideo"
import { Splash, useIntro } from "../components/Splash"

const ChapterListItem = ({
  title,
  slug,
  chapterNumber,
  summary,
  index,
  onMouseEnter,
  onMouseLeave,
  onClick,
}) => {
  // Context
  const { setMenuPath } = useNavigationContext()

  // UI
  const handleClick = () => {
    if (window.matchMedia("(hover: none)").matches) {
      // Touch
      navigate(`/${slug}`)
    } else {
      // No-touch
      onClick()
      setMenuPath(`/chapters/${slug}`)
    }
  }

  // CSS
  const style = {
    animationDelay: `${1 + index * 0.1}s`,
  }

  return (
    <div
      className="chapter-list__item prel block mbx8 p1"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={handleClick}
      style={style}
    >
      <div className="pabs">
        <em>
          <i>
            <span className="underline">{chapterNumber}</span>
          </i>
        </em>
      </div>
      <div className="item__text">
        <p>
          <span className="underline">
            <em className="upcase">
              <i>{title}</i>
            </em>
            , {summary.text}
          </span>
        </p>
      </div>
    </div>
  )
}

function useChapterListState() {
  const [show, setShow] = useState(true)
  const [render, setRender] = useState(true)

  return [show, setShow, render, setRender]
}

const ChapterList = ({
  tagline,
  chapters,
  handleChapterEnter,
  handleChapterLeave,
  handleChapterClick,
  introState,
  chapterListState,
}) => {
  const [height, setHeight] = useState("auto")
  const wrapper = useCallback((node) => {
    if (node) {
      setHeight(node.offsetHeight)
    }
  }, [])

  // UX
  const [introHidden] = introState
  const [show, setShow, render, setRender] = chapterListState

  const handleTransitionEnd = () => {
    // console.log('transitionEnd')
    if (!show) {
      setRender(false)
    }
  }

  useEffect(() => {
    if (show) {
      setRender(true)
      window.history.pushState("test", "SFTM", `/`)
    }
  }, [show])

  // UI
  const handleClick = (index) => {
    setShow(false)
    handleChapterClick(index) // Fakes the page change...
  }

  // CSS
  const style = {
    height: height,
    opacity: show && introHidden ? 1 : 0,
    transform: introHidden ? `translateY(0%)` : `translateY(100%)`,
    transition:
      "opacity 0.75s ease-in-out, transform var(--intro-speed) var(--intro-easing)",
  }

  return (
    render && (
      <section
        className={`chapter-list ${introHidden ? "reveal" : ""}`}
        onTransitionEnd={handleTransitionEnd}
        style={style}
      >
        <div className="grid">
          <div className="chapter-list__wrapper" ref={wrapper}>
            <div className="chapter-list-title">
              <p>{tagline}</p>
            </div>
            {chapters.map((entry, index) => (
              <ChapterListItem
                {...entry}
                index={index}
                onMouseEnter={() => handleChapterEnter(index)}
                onMouseLeave={handleChapterLeave}
                onClick={() => handleClick(index)}
                key={entry.slug}
              />
            ))}
          </div>
        </div>
      </section>
    )
  )
}

const Homepage = ({ data }) => {
  // Context
  const location = useLocation()
  const {
    currentMenuPath,
    setMenuPath,
    menuState: { setActivePath, handleMenuFocus },
  } = useNavigationContext()

  // Content
  const { tagline, splash, chapters, splashLockupText, campaignRows } =
    data.allContentfulHomepage.nodes[0]
  const [activeChapter, setChapter] = useState(null)
  const splashVisible = splash.mp4 || splash.vimeoUrl || splash.coverImage

  // UX
  const introState = useIntro(!splashVisible)
  const chapterListState = useChapterListState()
  const [showChapterList, setShowChapterList, renderList] = chapterListState
  const [scrollOffset, setScrollOffset] = useState(0)

  const reset = () => {
    setChapter(null)
    setShowChapterList(true)
    setMenuPath("/")
    setActivePath(null)
    handleMenuFocus(false)
  }

  // "Fake it till you make it"
  useEffect(() => {
    window.addEventListener("popstate", reset)
    return () => window.removeEventListener("popstate", reset)
  }, [])

  useEffect(() => {
    if (location.pathname === "/" && !showChapterList) {
      if (window.location.pathname === "/") {
        reset()
      }
    }
  }, [location.pathname, currentMenuPath])

  // UI
  const handleChapterEnter = (index) => {
    if (showChapterList) {
      setChapter(chapters[index])
    }
  }

  const handleChapterLeave = () => {
    if (showChapterList) {
      setChapter(null)
    }
  }

  const handleChapterClick = (index) => {
    if (showChapterList) {
      // Show route in URL
      window.history.pushState(
        "test",
        chapters[index].title,
        `/${chapters[index].slug}`
      )
      setScrollOffset(window.scrollY)
    }
  }

  return (
    <Layout>
      {splashVisible && (
        <Splash
          introState={introState}
          content={splash}
          splashLockupText={splashLockupText}
        />
      )}

      {renderList && (
        <CampaignRows
          rows={campaignRows}
          allShopifyProduct={data.allShopifyProduct.nodes}
          introState={introState}
        />
      )}

      <ChapterList
        tagline={tagline}
        chapters={chapters}
        handleChapterEnter={handleChapterEnter}
        handleChapterLeave={handleChapterLeave}
        handleChapterClick={handleChapterClick}
        introState={introState}
        chapterListState={chapterListState}
      />

      {activeChapter && (
        <section
          className={`homepage__moodboard guttersx4 ${
            renderList ? "is-preview" : ""
          }`}
          style={{
            marginTop: !renderList ? `${scrollOffset}px` : 0,
          }}
        >
          <Moodboard
            {...activeChapter}
            showCaptions={!showChapterList}
            isHomepageIntro={showChapterList}
          />
        </section>
      )}

      {/* {activeChapter && !showChapterList && (
				<ScreensaverLogo text={`${activeChapter.chapterNumber} ${activeChapter.title}`} />
			)} */}
    </Layout>
  )
}

Homepage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Homepage

export const HomepagePageQuery = graphql`
  fragment SplashFragment on ContentfulVideo {
    vimeoUrl
    width
    height
    coverImage {
      file {
        url
      }
      gatsbyImageData(layout: FULL_WIDTH, quality: 90)
    }
    mp4 {
      file {
        url
      }
    }
  }

  query HomepageQuery {
    allContentfulHomepage(
      filter: { contentful_id: { eq: "6wLa1PoXzuqHsrmGYji3WH" } }
    ) {
      nodes {
        tagline
        splash {
          ...SplashFragment
        }
        splashLockupText
        campaignRows {
          stories {
            contentful_id
            id
            internalLink
            chapterGalleryLink: chapter___gallery {
              slug
            }
            image {
              gatsbyImageData(layout: FULL_WIDTH, quality: 90, formats: [JPG])
            }
            imagePortrait {
              gatsbyImageData(layout: FULL_WIDTH, quality: 90, formats: [JPG])
            }
            note {
              raw
            }
            caption {
              raw
            }
            video {
              mp4 {
                file {
                  url
                }
              }
            }
            productLinks {
              shopifyId
              title
              colourMaterial
            }
            textColour
            linkLabel
          }
        }
        chapters {
          ...ContentfulChapterFragment
        }
      }
    }
    allShopifyProduct {
      nodes {
        handle
        featuredImage {
          gatsbyImageData(layout: FULL_WIDTH)
        }
        images {
          gatsbyImageData(layout: FULL_WIDTH)
          medium: gatsbyImageData(layout: CONSTRAINED, width: 640)
          large: gatsbyImageData(layout: FULL_WIDTH)
        }
        options {
          name
          values
        }
        variants {
          availableForSale
          storefrontId
          price
          compareAtPrice
          selectedOptions {
            value
          }
        }
      }
    }
  }
`
