// Dependencies
import React, { useState, useEffect, useCallback, useRef } from "react"
import { useWheel, useDrag } from 'react-use-gesture'
// Hooks & Helpers
import useLockBodyScroll from "../hooks/useLockBodyScroll";
import useSessionStorage from "../hooks/useSessionStorage";
import useTimeout from "../hooks/useTimeout"
import useMount from "../hooks/useMount"
// Components
import Lockup from "../components/Lockup"
import SplashVideo from "../components/SplashVideo"
import Image from "../components/Image"

export function useIntro(skipIntro) {

	const introElementRef = useRef()
	const [isFinished, setFinish] = useSessionStorage('sftm_is_intro_finished', skipIntro)
	const [isHidden, setHide] = useSessionStorage('sftm_is_intro_hidden', skipIntro)

	// UX
	useLockBodyScroll(!isFinished, introElementRef)
	
	const handleTransitionEnd = (event) => {
		event.stopPropagation() // This doesn't seem to work...
		if (isHidden && (event.target === introElementRef.current)) {
			setFinish(true)
			window.scrollTo(0, 0)
			window?.document.body.classList.remove('is-locked')
		}
	}

	// UI
	const hideIntro = () => {
		setHide(true)
		window?.document.body.classList.remove('splash-visible')
	}

	return [isHidden, isFinished, handleTransitionEnd, hideIntro, introElementRef]
}

export const Splash = ({ 
	introState, 
	content, 
	splashLockupText 
}) => {

	// Prevent hydration errors
	const isMounted = useMount()

	// UX
	const [hide, finish, handleTransitionEnd, hideIntro, ref] = introState

	// Make sure it starts at top of page
	useTimeout(() => {
		window.scrollTo(0, 0)
	}, 800)

	useEffect(() => {
		if (!hide) {
			window?.document.body.classList.add('splash-visible', 'is-locked')
		}
		return () => window?.document.body.classList.remove('splash-visible', 'is-locked')
	}, [])

	// Hide on timeout
	// useTimeout(() => {
	// 	hideIntro()
	// }, 5000)

	const bindWheel = useWheel(({ first }) => {
		if (first) {
			hideIntro()
		}
	})

	const bindDrag = useDrag(({ first }) => {
		if (first) {
			hideIntro()
		}
	})

	// CSS
	const style = {
		transform: hide ? `translateY(-100%)` : `translateY(0%)`,
		transition: `transform var(--intro-speed) var(--intro-easing)`
	}

	return (
		!finish && isMounted && (
			<section 
				className="homepage__splash fv flex-center" 
				{...bindWheel()} 
				{...bindDrag()} 
				onClick={hideIntro} 
				onTransitionEnd={handleTransitionEnd} 
				style={style} 
				ref={ref}
			>
				{content && (
					(content.mp4 || content.vimeoUrl) ? (
						<SplashVideo {...content} />
					) : content.coverImage ? (
						<Image className="pabs fs" image={content.coverImage.gatsbyImageData} />
					) : null
				)}
				
				<Lockup text={splashLockupText} />
			</section>
		)
	)
}
