// Dependencies
import React, { useEffect, useState, useRef } from "react"
import { Story } from "../templates/chapter-gallery"
import Moodboard from "./Moodboard"
import { ProductThumbnailInfo } from './ProductThumbnail'
import useRandomPosition from "../hooks/useRandomPosition"
import { useInView } from 'react-intersection-observer'
import { Link } from "gatsby"
import matchProductId from "../helpers/matchProductId"

const CampaignRowItem = ({
  delay = 0,
  startY,
  randomWidth = false,
  story,
  children
}) => {
  const adjust = useRandomPosition(10)
  const start = useRandomPosition(0, startY)
  const randomNumber = useRef(Math.random())
  const width = randomWidth ? randomNumber.current * (100 - 80) + 80 : 100
  const startYAbs = Math.abs(start.y)
  const [startTransform, setStart] = useState(`translateY(${startYAbs}%)`)

  useEffect(() => {
    if (window.matchMedia("(hover: hover)").matches) {
      setStart(`translateY(${startYAbs}%)`)
    } else {
      setStart(`translate(0%, 0%)`)
    }
  }, [])

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true
  });

  const transition = '1.33s transform, 1s opacity'
  const style = inView ? {
    transform: startTransform,
    opacity: 1,
    width: `${width}%`,
    transition,
    transitionDelay: delay
  } : {
      transform: `translateY(${startYAbs * adjust}%)`,
    opacity: 0,
      width: `${width}%`,
    transition,
    transitionDelay: delay
  }

  const link = story.productLinks?.length === 1 ? `/product/${story.productLinks[0].handle}` : story.internalLink

  const showProductInfo = story.productLinks?.length === 1

  return link ? (
    <Link to={link} className="campaign-row-item" style={style} ref={ref}>
      <>
        {children}
        {showProductInfo && (
          <div className="campaign-row-product-info">
            <ProductThumbnailInfo product={story.productLinks[0]} />
          </div>
        )}
      </>
    </Link>
  ) : (
    <div className="campaign-row-item" style={style} ref={ref}>
      {children}
    </div>
  )
}

const CampaignRowItems = ({ row, allShopifyProduct }) => {
  const stories = row.stories?.map((story) => {
    const productLinks = story.productLinks?.map((product) => {
      const shopifyProduct = allShopifyProduct?.find((item) => {
        return item.variants.find(variant => matchProductId(variant.storefrontId, product?.shopifyId))
      })
      return {
        ...product,
        ...shopifyProduct
      }
    })
    return {
      ...story,
      productLinks: productLinks
    }
  })

  if (stories?.length > 2) {
    return (
      <div className="campaign-row">
        <Moodboard stories={stories} showCaptions={true} />
      </div>
    )
  }

  if (stories?.length === 2) {
    return (
      <div className="campaign-row two-up">
        {stories.map((story, index) => (
          <CampaignRowItem
            delay={index * 0.7}
            startY={index > 0 ? 30 : 0}
            key={story.id}
            story={story}
            randomWidth
          >
            <Story {...story} index={1} productLinks={story.productLinks?.length > 1 ? story.productLinks : []} />
          </CampaignRowItem>
        ))}
      </div>
    )
  }

  const story = stories?.[0]

  if (story) {
    return (
      <div className="campaign-row">
        <CampaignRowItem delay={0.7} startY={0} key={story.id} story={story}>
          <Story {...story} index={1} productLinks={story.productLinks?.length > 1 ? story.productLinks : []} />
        </CampaignRowItem>
      </div>
    )
  }

  return null;
}

const CampaignRows = ({ rows, allShopifyProduct, introState }) => {
  const [introHidden] = introState

  const style = {
    opacity: introHidden ? 1 : 0,
    transform: introHidden ? `translateY(0%)` : `translateY(100%)`,
    transition: 'opacity 0.75s ease-in-out, transform var(--intro-speed) var(--intro-easing)'
  }

  return (
    <div style={style}>
      <div className="campaign-rows">
        {rows.map((row, index) => (
          <CampaignRowItems row={row} key={`campaign-row-${index}`} allShopifyProduct={allShopifyProduct} />
        ))}
      </div>
    </div>
  )
}

export default CampaignRows
