// Dependencies
import React, { useEffect, useRef, useState } from 'react'
import { Link, graphql } from 'gatsby'
// Hooks
import useVimeo from '../hooks/useVimeo'

const Vimeo = ({ vimeoUrl, width, height }) => {

	const [ref] = useVimeo({
		url: vimeoUrl,
		byline: false,
		color: "#FFF",
		portrait: false,
		title: false,
		autoplay: true,
		background: 1
	})

	const style = {
		height: `${height/width*100}vw`,
		minHeight: `100vh`,
		width: `100vw`,
		minWidth: `${width/height*100}vh`
	}

	return (
		<div className="vimeo-full-bleed" style={style}>
			<div ref={ref} />
		</div>
	)
}

const Video = ({ src }) => {

	const [mounted, setMounted] = useState(false)
	const [willPlay, set] = useState(false)

	useEffect(() => {
		setMounted(true)
	}, [])

	const handlePlay = (event) => {
		set(true)
	}

	const style = {
		opacity: willPlay ? 1 : 0
	}

	const hasHttp = src.match(/^http:\/\//i)
	const secureUrl = hasHttp ? src.replace(/^http:\/\//i, 'https://') : `https:${src}`

	return (
		mounted && (
			<video className="pabs fs cover" autoPlay muted loop playsInline controls={false} onPlaying={handlePlay} style={style}>
				<source src={secureUrl} type="video/mp4" />
			</video>
		)
	)
	// return (
	// 	<video className="pabs fs cover" autoPlay muted loop playsInline controls={false}>
	// 		<source src={secureUrl} type="video/mp4" />
	// 	</video>
	// )
}

const SplashVideo = (props) => {
	
	return (
		<div className="splash__video">
			{props.vimeoUrl ? (
				<Vimeo {...props} />
			) : props.mp4?.file?.url ? (
				<Video src={props.mp4.file.url} />
			) : null}
		</div>
	)
}

export default SplashVideo